import Const from '../constants'
import axiosApiInstance from '../interceptors'

class PasswordService {
  forgotPassword(email: any) {
    let site_url = window.location.origin + '/reset-password'
    return axiosApiInstance.post(Const.api_url + 'auth/forgot-password', {
      email,
      site_url
    })
  }

  resetPassword(password: string, password_confirmation: string) {
    let token = new URLSearchParams(window.location.search).get(
      'token'
    )
    return axiosApiInstance.post(Const.api_url + 'auth/reset-password', {
      token,
      password,
      password_confirmation
    })
  }
}

export default new PasswordService()